import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/axios'
import './icons'

import VueCookies from 'vue-cookies';
import * as echarts from 'echarts'
Vue.use(VueCookies);
Vue.use(echarts);
Vue.prototype.VueCookies = VueCookies;
Vue.prototype.$echarts = echarts;

import iconPicker from 'e-icon-picker';
import "e-icon-picker/lib/symbol.js"; //基本彩色图标库
import 'e-icon-picker/lib/index.css'; // 基本样式，包含基本图标
import 'font-awesome/css/font-awesome.min.css'; //font-awesome 图标库
import 'element-ui/lib/theme-chalk/icon.css'; //element-ui 图标库
Vue.use(iconPicker, { FontAwesome: false, ElementUI: true, eIcon: false, eIconSymbol: false }); 

import './assets/css/icons/icomoon/styles.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { on } from 'element-ui/src/utils/dom'
Vue.use(ElementUI);

import './assets/css/element-variables.scss'; 
import './assets/css/style.scss'; 

import RightToolbar from '@/components/RightToolbar'
import Pagination from '@/components/Pagination'
Vue.component('RightToolbar', RightToolbar)
Vue.component('Pagination', Pagination)

import { resetForm, handleDateRange, getTreeCheckedKeys, setTreeNodeCheck } from '@/utils/common'
Vue.prototype.resetForm = resetForm
Vue.prototype.handleDateRange = handleDateRange
Vue.prototype.getTreeCheckedKeys = getTreeCheckedKeys
Vue.prototype.setTreeNodeCheck = setTreeNodeCheck

import $util from "@/common/util.js"
Vue.prototype.$util = $util;

Vue.prototype.$eventBus = new Vue();

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
